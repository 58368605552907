<template>
    
    <van-nav-bar :title="title" left-arrow placeholder fixed safe-area-inset-top
        @click-left="onClickBack">
        <template v-if="likeId" #right>
            <van-icon :name="iconName" size="18" color="red" @click="onClickLike" />
        </template>
    </van-nav-bar>

</template>

<script>
import { Toast } from 'vant';
import common from '../utils/common'

export default {
    name: 'PageNavBar',
    components: {
    },
    props: {
        title: {
            type: String,
            default: ''
        },
        likeId: {
            type: String,
            default: ''
        },
        goBack: Function
    },
    setup() {
    },
    data() {
        return {
            hasCollected: false,
        }
    },
    mounted() {
        this.hasCollected = common.hasCollected(this.likeId)
    },
    computed: {
        iconName() {
            return this.hasCollected ? "like" : "like-o"
        },
    },
    methods: {
        onClickBack() {
            if (this.goBack) {
                this.goBack()
            } else {
                this.$router.back()
            }
        },
        /**
         * 点击收藏/取消收藏
         */
        onClickLike() {
            if (this.hasCollected) {
                common.removeArticleFavorite(this.likeId)
            } else {
                common.addArticleFavorite(this.likeId)
            }
            this.hasCollected = common.hasCollected(this.likeId)

            let msg = this.hasCollected ? '收藏成功！！！' : '取消收藏成功'
            Toast.success(msg)
        }
    }
}
</script>

<style lang="stylus" scoped>
</style>