import request from '../utils/request'

/**
 * 获取当前登录用户信息
 * @returns 当前用户信息
 */
export const getUserInfo = () => {
    let data = ``
    return request.get(`/user/profile`, data, {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    }).then(res => res.data.data)
}

/**
 * 获取所有用户账号信息列表
 * @returns 所有用户账号信息列表
 */
export const getUserList = () => {
    let data = ``
    return request.get(`/user/list`, data, {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    }).then(res => res.data.data)
}

/**
 * 获取指定用户的详情信息
 * @param {用户Id} userId 
 * @returns 用户详情
 */
export const getUserDetail = (userId) => {
    let data = ``
    return request.get(`/user/detail/${userId}`, data, {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    }).then(res => res.data.data)
}

/**
 * 新增用户信息
 * @param {用户信息} data 
 * @returns 
 */
export const addUser = (data) => {
    return request.post(`/user/add`, data, {
        headers: {
            'Content-Type': 'application/json'
        }
    }).then(res => res.data)
}

/**
 * 更新用户信息
 * @param {用户信息} data 
 * @returns 
 */
export const updateUser = (data) => {
    return request.post(`/user/info`, data, {
        headers: {
            'Content-Type': 'application/json'
        }
    }).then(res => res.data)
}

/**
 * 删除用户信息
 * @param {用户Id} userId 
 * @returns 
 */
export const deleteUser = (userId) => {
    let data = ``
    return request.delete(`/user/info/${userId}`, data, {
        headers: {
            'Content-Type': 'application/json'
        }
    }).then(res => res.data)
}
