<template>
    <div class="users">
        <PageNavBar title="用户管理"/>

        <van-contact-card type="add" is-link url="/user/add"/>

        <van-pull-refresh v-model="refreshing" @refresh="onRefresh" class="van-clearfix">
            <van-list>
                <template v-for="user in user_list" :key="user.userId">
                    <van-contact-card type="edit" :tel="user.phoneNumber" :name="user.nickName" @click="onEdit(user)" />
                </template>
            </van-list>
        </van-pull-refresh>

    </div>
</template>

<script>
import PageNavBar from '../../components/PageNavBar'
import { getUserList } from '../../api/user'

export default {
    name: 'UserListPage',
    components: {
        PageNavBar,
    },
    props: {
    },
    setup() {
    },
    created() {
        this.onRefresh()
    },
    mounted() {
    },
    data() {
        return {
            refreshing: false,
            user_list: []
        }
    },
    methods: {
        async onRefresh() {
            this.refreshing = true
            this.user_list = await getUserList()
            this.refreshing = false
        },
        onEdit(user) {
            console.log('onEdit . user: ', JSON.stringify(user))

            this.$router.push(`/user/edit/${user.userId}`)
        }
    }
}
</script>

<style lang="stylus" scoped>
.van-list
    margin-top: 1em
</style> 